import tippy, {roundArrow} from 'tippy.js';
window.tippy = tippy;
import moment from 'moment';
window.moment = moment;
import Pikaday from 'pikaday';
window.Pikaday = Pikaday;
import Quill from 'quill';
window.Quill = Quill;
import fitty from 'fitty';
window.fitty = fitty;
import lottie from 'lottie-web/build/player/lottie_light';
window.lottie = lottie;
import ColorThief from 'colorthief/dist/color-thief.mjs'
window.ColorThief = new ColorThief();
import InquiryForm from './inquiry.js';
window.InquiryForm = InquiryForm;

// send CSRF token as a header based on the value of the "XSRF" token cookie
import axios from 'axios';
window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
let token = document.head.querySelector('meta[name="csrf-token"]');
if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';
window.Alpine = Alpine;
window.Livewire = Livewire;

// add Alpine plugins
import ui from '@alpinejs/ui';
Alpine.plugin(ui);

// setup Alpine magics/directives
Alpine.magic('tooltip', (el) => (message) => {
    let instance = tippy(el, { content: message, trigger: 'manual' });
    instance.show();
    setTimeout(() => {
        instance.hide();
        setTimeout(() => instance.destroy(), 150);
    }, 2000);
});

// directive: x-tooltip
Alpine.directive('tooltip', (el, { expression }) => {
    tippy(el, { content: expression });
});

// import Alpine components
import tableComponent from './alpine/table-component.js'

// will be executed before initializing Alpine
document.addEventListener('alpine:init', () => {
    // console.log('alpine:init')
    // register Alpine components
    Alpine.data('tableComponent', tableComponent);
});

// will be executed after initializing Alpine
document.addEventListener('alpine:initialized', () => {
    console.log('alpine:initialized')
});

// runs after Livewire is loaded but before it's initialized
document.addEventListener('livewire:init', () => {
    // console.log('livewire:init')
})

// runs immediately after Livewire has finished initializing
document.addEventListener('livewire:initialized', () => {
    console.log('livewire:initialized')
})

// global event handlers
window.addEventListener('scroll-top', () => {
    window.scrollTop();
});

// onload
document.addEventListener('DOMContentLoaded', () => {
    console.log('DOMContentLoaded -- width: ' + window.getWidth());

    // init tooltips
    window.initTippy();

    // init alpha
    window.initAlpha();

    // homepage rocket
    const rocket = document.getElementById('rocket');
    if (rocket) {
        lottie.loadAnimation({
            container: rocket, // the dom element that will contain the animation
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: '/static/img/rocket.json' // the path to the animation json
        });
    }
});

// common functions
window.scrollTop = function() {
    window.scrollTo({top: 0, behavior: 'smooth'});
}
window.getWidth = function() {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
}
window.getHeight = function() {
    return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
}
window.isMobile = function() {
    return window.getWidth() < 475
}
window.isXtraSmall = function() {
    return window.getWidth() >= 475 && window.getWidth() < 640
}
window.isSmall = function() {
    return window.getWidth() >= 640 && window.getWidth() < 768
}
window.isMedium = function() {
    return window.getWidth() >= 768 && window.getWidth() < 1024
}
window.isLarge = function() {
    return window.getWidth() >= 1024 && window.getWidth() < 1280
}
window.isXtraLarge = function() {
    return window.getWidth() >= 1280 && window.getWidth() < 1536
}

// test for objectness
window.isObject = function(o) {
    return o !== null && typeof o === 'object' && Array.isArray(o) === false;
}

// trigger open-modal event and the correct modal will open based on action
window.openModal = function(action, context) {
    context = typeof context !== 'undefined' ? context : [];
    console.log('openModal', action, context);
    window.dispatchEvent(new CustomEvent('open-modal', {
        detail: {
            'action': action,
            'context': context,
        }
    }));
}

// show notification
window.notification = function(message, style, timeout) {
    style = typeof style !== 'undefined' ? style : 'success';
    timeout = typeof timeout !== 'undefined' ? timeout : 8000;

    window.dispatchEvent(new CustomEvent('notification', {
        detail: {
            'message': message,
            'style': style,
            'timeout': timeout
        }
    }));
}

// show banner
window.banner = function(message, style, timeout) {
    style = typeof style !== 'undefined' ? style : 'success';
    timeout = typeof timeout !== 'undefined' ? timeout : 8000;

    window.dispatchEvent(new CustomEvent('banner', {
        detail: {
            'message': message,
            'style': style,
            'timeout': timeout
        }
    }));
}

// update query string and pushState
window.setQueryStringParam = function(key, value, pushState=false) {
    let qs = window.location.search,
        uri = window.location.pathname;
        qs = window.updateUrlParameter(qs, key, value);
    if (pushState) {
        // add state to history stack and enable back button
        history.pushState(null, '', uri + qs);
    } else {
        // replace current url
        history.replaceState(null, '', uri + qs);
    }
    // console.log('setQS', key, value, qs, pushState)
}

// https://gist.github.com/niyazpk/f8ac616f181f6042d1e0#gistcomment-3461639
window.updateUrlParameter = function(uri, key, value) {
    // remove the hash part before operating on the uri
    var i = uri.indexOf('#');
    var hash = i === -1 ? ''  : uri.substr(i);
    uri = i === -1 ? uri : uri.substr(0, i);

    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";

    if (value === null) {
        // remove key-value pair if value is specifically null
        uri = uri.replace(new RegExp("([?&]?)" + key + "=[^&]*", "i"), '');
        if (uri.slice(-1) === '?') {
            uri = uri.slice(0, -1);
        }
        // replace first occurrence of & by ? if no ? is present
        if (uri.indexOf('?') === -1) uri = uri.replace(/&/, '?');
    } else if (uri.match(re)) {
        uri = uri.replace(re, '$1' + key + "=" + value + '$2');
    } else {
        uri = uri + separator + key + "=" + value;
    }
    return uri + hash;
}

// init tippy tooltips
window.initTippy = function(theme) {
    // init and re-init all Tippy instances to get fresh content
    let instances = document.querySelectorAll('[data-tippy-content]');
    for (let i = 0; i < instances.length; ++i) {
        let el = instances[i];
        if (el.hasOwnProperty('_tippy')) {
            el._tippy.destroy();
        }
        let offset = [0, 10];
        if (el.hasAttribute('data-tippy-offset-x')) {
            offset[0] = parseInt(el.getAttribute('data-tippy-offset-x'));
        }
        if (el.hasAttribute('data-tippy-offset-y')) {
            offset[1] = parseInt(el.getAttribute('data-tippy-offset-y'));
        }
        let distance = 0;
        if (el.hasAttribute('data-tippy-distance')) {
            distance = parseInt(el.getAttribute('data-tippy-distance'));
        }
        tippy(el, {
            duration: [50, 200],
            placement: 'top',
            allowHTML: true,
            theme: theme ? theme : 'darq',
            arrow: roundArrow,
            offset: offset,
            interactiveBorder: 0,
            // flip: false,
            // distance: distance,
            // animation: 'perspective-subtle',
        });
    }
};

// init alpha tooltips
window.initAlpha = function() {
    let instances = document.querySelectorAll('sup.alpha');
    for (let i = 0; i < instances.length; ++i) {
        let el = instances[i];
        if (el.hasOwnProperty('_tippy')) {
            el._tippy.destroy();
        }
        let offset = [0, 8];
        tippy(el, {
            duration: [50, 200],
            placement: 'right',
            allowHTML: true,
            theme: 'alpha',
            arrow: roundArrow,
            offset: offset,
            content: 'Coming soon!',
        });
    }
};

Livewire.start();
export default function tableComponent() {
    return {
        componentName: 'table-component',
        search: '',
        numFilters: 0,
        selectAll: false,
        selected: [],
        unselected: [],
        numSelected: 0,
        showingBackdrop: false,
        showingLoading: false,
        showingFilters: false,
        // components can override this
        init() {
            this.initTable();
        },
        // common init stuff for table-components
        initTable() {

            // watch for filter changes and update numFilters
            this.$wire.$watch('filters', (value) => {
                this.numFilters = this.getNumFilters();
            });

            // listen for refreshes and trigger onRender
            this.$wire.on('rendered', () => {
                this.$nextTick(() => this.onRender());
            });

            // open filters drawer if any filters are set on initial page load (or search for mobile)
            this.numFilters = this.getNumFilters();
            this.showingFilters = this.numFilters > 0;

            // run init stuff
            this.onRender();

            // adjust search placeholder
            // if (window.isMobile()) {
            //     document.getElementById('search').placeholder = 'Search...';
            // }
        },
        // runs on initial mount and every re-render
        onRender() {
            // init tooltips
            window.initTippy();
        },

        // Advanced Filters
        hideFilters() {
            this.showingFilters = false;
        },
        toggleFilters() {
            this.showingFilters = !this.showingFilters
        },
        // get currently active filters
        getFilters() {
            return this.$wire.get('filters');
        },
        getNumFilters() {
            let filters = this.getFilters();
            let numFilters = this.$wire.get('numFilters');
            // exclude search from filters on desktop, include it on mobile
            if (!this.mobileFilters() && numFilters > 0 && this.search !== "" && this.search !== null) {
                numFilters--;
            }
            // always exclude market and issue filters from listings page
            if (typeof filters === 'object' && filters !== null) {
                if (numFilters > 0 && filters.hasOwnProperty('market') && filters.market !== null) {
                    numFilters--;
                }
                if (numFilters > 0 && filters.hasOwnProperty('issue') && filters.issue !== null) {
                    numFilters--;
                }
            }
            return numFilters;
        },
        mobileFilters() {
            return (window.isMobile() || window.isXtraSmall() || window.isSmall());
        },
        // custom filters (combination of filters)
        filterTable(filter) {
            this.$wire.call('filterTable', filter)
                .then(result => {
                    // console.log('filterDomains');
                });
        },

        // get current sorting
        getSorting() {
            return {
                sort: this.$wire.get('sort'),
                direction: this.$wire.get('direction'),
            }
        },
        getTotalRows() {
            // filtered rows total
            return this.$wire.get('totalRows');
        },

        // Refresh/Reset
        showingReset() {
            // determine if reset button should be shown/highlighted
            return this.showingFilters || this.numSelected > 0 || this.$wire.get('showingReset');
        },
        refreshTable() {
            this.$wire.call('refreshTable');
        },
        resetSearch() {
            const searchElement = document.getElementById('search');
            const filterSearchElement = document.getElementById('filter_search');
            if (searchElement) {
                searchElement.value = '';
            }
            if (filterSearchElement) {
                filterSearchElement.value = '';
            }
            this.search = '';
        },
        resetTable() {
            this.numFilters = 0; // this hides reset button right away
            this.resetSearch()
            this.hideFilters()
            this.resetSelection() // clearing selection is also triggered in livewire methods
            this.$wire.call('resetTable');
        },
        resetSelection() {
            this.numSelected = 0;
            this.selected = [];
            this.unselected = [];
            this.selectAll = false;
            // uncheck all rows on page
            let checkboxes = document.getElementsByClassName('row-select');
            if (checkboxes) {
                for (let i = 0; i < checkboxes.length; ++i) {
                    checkboxes[i].checked = false;
                    // remove purple styles from selected rows
                    this.toggleSelectedClasses(checkboxes[i], false);
                }
            }
            // clear selectAll
            let selectAllRows = document.getElementById('select-all-rows');
            if (selectAllRows) {
                selectAllRows.checked = false;
                selectAllRows.indeterminate = false;
            }
        },

        // Select All
        toggleSelectAll(event) {
            this.selected = [];
            this.unselected = [];
            this.selectAll = event.target.checked;
            // check/uncheck all domains on page
            let checkboxes = document.getElementsByClassName('row-select');
            if (checkboxes) {
                for (let i = 0; i < checkboxes.length; ++i) {
                    checkboxes[i].checked = this.selectAll;
                    // add/remove purple styles from selected rows
                    this.toggleSelectedClasses(checkboxes[i], this.selectAll);
                }
            }
            // set num selected to all or none
            this.numSelected = (event.target.checked) ? this.getTotalRows() : 0;
        },

        // Select Rows
        toggleSelected(event) {
            // console.log('toggleSelected', event.target.value, event.target.checked);
            if (event.target.checked) {
                // remove from unselected
                this.unselected = this.unselected.filter(function(d) { return d !== event.target.value});
                // add to selected if it isn't already
                if (this.selected.indexOf(event.target.value) === -1) {
                    this.selected.push(event.target.value);
                    this.numSelected++;
                }
                // add purple styles to selected rows
                this.toggleSelectedClasses(event.target, true);
            } else {
                // remove from selected
                this.selected = this.selected.filter(function(d) { return d !== event.target.value});
                if (this.numSelected > 0) {
                    this.numSelected--;
                }
                // with selectAll checked
                if (this.selectAll) {
                    // add to unselected if it isn't already
                    if (this.unselected.indexOf(event.target.value) === -1) {
                        this.unselected.push(event.target.value);
                    }
                }
                // remove purple styles from selected rows
                this.toggleSelectedClasses(event.target, false);
            }
            // handle selectAll checkbox
            // if selectAll and none unselected, remove indeterminate
            if (this.selectAll) {
                if (this.selected.length === 0 && this.unselected.length === 0) {
                    document.getElementById('select-all-rows').indeterminate = false;
                    document.getElementById('select-all-rows').checked = true;
                }
                // some unselected, set indeterminate
                if (this.selectAll && this.unselected.length !== 0) {
                    document.getElementById('select-all-rows').indeterminate = true;
                }
                // all unselected, uncheck selectAll
                if (this.selectAll && this.unselected.length === this.getTotalRows()) {
                    document.getElementById('select-all-rows').indeterminate = false;
                    document.getElementById('select-all-rows').checked = false;
                    this.selectAll = false;
                }

            } else {
                // all rows have been manually selected
                if (this.selected.length === this.getTotalRows()) {
                    document.getElementById('select-all-rows').indeterminate = false;
                    document.getElementById('select-all-rows').checked = true;
                } else {
                    // set indeterminate if some are checked, otherwise clear it
                    document.getElementById('select-all-rows').indeterminate = (this.selected.length > 0);
                }
            }
        },

        // add/remove styles to highlight selected rows
        toggleSelectedClasses(el, selected) {
            let parentRow = el.parentNode.parentNode.parentNode,
                selectedClasses = ['!bg-highlight-200', '!bg-opacity-40', '!border-highlight-200', '!border-t', '!border-b'];
            // only work with domain rows
            if (parentRow) {
                if (parentRow.classList.contains('domain-row')) {
                    if (selected) {
                        parentRow.classList.add.apply(
                            parentRow.classList,
                            selectedClasses
                        );
                    } else {
                        parentRow.classList.remove.apply(
                            parentRow.classList,
                            selectedClasses
                        );
                    }
                }
            }
        },

        // get bulk selection details for modals
        getSelection(rows, select_all) {
            // if select_all, use it and ignore everything else
            select_all = typeof select_all !== 'undefined' ? select_all : false;
            if (select_all) {
                return {
                    selectAll: true,
                    numSelected: null,
                    selected: [],
                    unselected: [],
                }
            }

            // else if rows are supplied, use them for selected in backend and clear everything else
            rows = typeof rows !== 'undefined' ? rows : [];
            if (Array.isArray(rows) && rows.length > 0) {
                return {
                    selectAll: false,
                    numSelected: rows.length,
                    selected: rows,
                    unselected: [],
                }
            } else {
                return {
                    selectAll: this.selectAll,
                    numSelected: this.numSelected,
                    selected: this.selected,
                    unselected: this.unselected,
                }
            }
        },

        // get full filter/sort/selected context for modals
        getContext() {
            return {
                component: this.componentName,
                filters: this.getFilters(),
                sorting: this.getSorting(),
                selection: this.getSelection(),
            };
        },

        // ensure selection has at least one selected row
        validateSelection(selection) {
            if (window.isObject(selection)) {
                if (selection.hasOwnProperty('selected') && selection.selected.length > 0) {
                    return true;
                } else if (selection.hasOwnProperty('selectAll') && selection.selectAll) {
                    return true;
                } else if (selection.hasOwnProperty('numSelected') && selection.numSelected > 0) {
                    return true;
                }
            }
            alert('You must select at least one row.');
            console.error('Invalid selection context');
            return false;
        },

        // refresh from bulk actions menu
        refreshSelected() {
            let selection = this.getSelection();
            if (this.validateSelection(selection)) {
                if (this.componentName === 'markets-component') {
                    window.notification('The selected listings are being updated.', 'info');
                } else {
                    window.notification('The selected domains are being updated.', 'info');
                }
                this.refreshingSelected();
                this.$wire.call('refreshSelected', selection)
                    .then(result => {
                        // this.refreshingSelected();
                        this.resetSelection();
                    });
            }
        },
        // mark selected as loading
        refreshingSelected() {
            let checkboxes = document.getElementsByClassName('row-select');
            if (checkboxes) {
                for (let i = 0; i < checkboxes.length; ++i) {
                    if (checkboxes[i].checked) {
                        let data_cells = checkboxes[i].parentNode.parentNode.parentNode.querySelectorAll('.data-cell');
                        for (let i = 0; i < data_cells.length; ++i) {
                            let c = data_cells[i];
                            c.innerHTML = '<div class="font-medium !text-gray-700 ztext-gray-400 ztext-left !opacity-50"><svg tabindex="-1" class="inline-block !h-[1.5rem] !w-[1.5rem] animate-pulse focus:outline-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"> <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"></path></svg></div>';
                        }

                        // clear out old refresh button while we are refreshing
                        let last_checked_cell = checkboxes[i].parentNode.parentNode.parentNode.querySelector('.last-checked-cell');
                        if (last_checked_cell) {
                            // last_checked_cell.innerHTML = '';
                            last_checked_cell.classList.add('!justify-end');
                            last_checked_cell.innerHTML = '<div class="font-medium !text-gray-700 ztext-gray-400 text-right !opacity-50"><svg tabindex="-1" class="inline-block !h-[1.5rem] !w-[1.5rem] animate-pulse focus:outline-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"> <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"></path></svg></div>';
                        }
                    }
                }
            }
        },
        // export from bulk actions menu
        exportSelected() {
            this.$wire.call('exportSelected')
                .then(result => {
                    window.notification('Export completed!');
                });
        },
        // export from export button
        exportFiltered() {
            this.$wire.call('exportFiltered')
                .then(result => {
                    window.notification('Export completed!');
                });
        },
        copySelected() {
            this.$wire.call('copySelected')
                .then(result => {
                    if (result.hasOwnProperty('results')) {
                        if (window.isSecureContext && navigator.clipboard && navigator.clipboard.writeText) {
                            navigator.clipboard.writeText(result.results).then(() => {
                                window.notification(result.count + ' domain names have been copied to your clipboard.');
                            })
                        } else {
                            console.error('No clipboard support');
                        }
                    }
                });
        },

        // trigger Single or Bulk Action w/ selection context
        modalAction(event) {
            let action = event.detail.hasOwnProperty('action') ? event.detail.action : null,
                rows = event.detail.hasOwnProperty('rows') ? event.detail.rows : [],
                select_all = event.detail.hasOwnProperty('select_all') ? event.detail.select_all : false;
            if (action === null) {
                console.error('Invalid modal action');
                return;
            }

            // build modal context
            let context = {
                ...event.detail, // any other context from event, e.g. market
                action: action,
                rows: rows,
                select_all: select_all,
                ...this.getContext(),
                // pass rows to getSelection in case any were specified
                selection: this.getSelection(rows, select_all)
            };

            // delete select_all and rows out of root context
            delete context.select_all;
            delete context.rows;

            // trigger open-modal event and the correct modal will open based on action
            this.$dispatch('open-modal', context);
        },
        // shortcut to trigger above without creating event
        triggerModalAction(action, rows) {
            this.modalAction({
                detail: {
                    'action': action,
                    'rows': rows,
                }
            });
        },

        // global modal backdrop
        showBackdrop() {
            this.showingBackdrop = true;
            this.showingLoading = true
        },
        hideBackdrop() {
            this.showingBackdrop = false;
            this.showingLoading = false
        },
        hideLoading() {
            this.showingLoading = false
        },
        closeModals() {
            // global event to close all open modals
            window.dispatchEvent(new CustomEvent('close-modals'));
        },

        // debug stuff
        debugBulk() {
            this.$dispatch('modal-action', {'action': 'bulk-pricing'});
        },
        debugFilterTable() {
            this.$wire.call('filterTable', 'non-bin');
        },
        debugTestModal() {
            this.$dispatch('modal-action', {'action': 'test'});
        },
        debugEntangled() {
            console.log('search: ', this.search);
            console.log('selected: ', this.selected);
            console.log('raw search: ', Alpine.raw(this.search));
            console.log('raw selected: ', Alpine.raw(this.selected));
            console.log('resolved search: ', this.resolveEntangled(this.search));
            console.log('resolved selected: ', this.resolveEntangled(this.selected));
        },
        // for debugging entangled props
        resolveEntangled(entangledProp) {
            const rawProp = Alpine.raw(entangledProp);

            // check if it's the Livewire entanglement structure
            if (rawProp && rawProp.initialValue !== undefined) {
                const initialValue = Alpine.raw(rawProp.initialValue);

                // handle arrays
                if (Array.isArray(initialValue)) {
                    return Array.from(initialValue).map(item => Alpine.raw(item));
                }
                // handle objects
                if (typeof initialValue === 'object' && initialValue !== null) {
                    return Object.fromEntries(
                        Object.entries(initialValue).map(([key, value]) => [key, Alpine.raw(value)])
                    );
                }
                return initialValue;
            }
            return rawProp;
        }
    }
}